<template>
  <div>
    <section class="section intro">
      <div class="level">
        <div class="level-left">
          <h1 class="title has-text-centered-mobile is-size-5-mobile">
            {{ $t("Tasks") }}
          </h1>
        </div>
        <!-- <div class="level-right">
          <div class="level-item">
            <b-button
              size="is-medium is-primary"
              @click.prevent="confirmProcessTasks()"
              >Proceseaza sarcinile</b-button
            >
          </div>
        </div> -->
      </div>
    </section>
    <section class="section">
      <b-table
        v-if="tasks && tasks.length"
        :current-page.sync="currentPage"
        :data="normalizedTasks"
        :debounce-search="1000"
        :default-sort-direction="defaultSortDirection"
        :paginated="isPaginated"
        :pagination-position="paginationPosition"
        :pagination-rounded="isPaginationRounded"
        :pagination-simple="isPaginationSimple"
        :per-page="perPage"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        default-sort="projectName"
      >
        <b-table-column
          v-slot="props"
          :width="250"
          field="fileName"
          label="Nume fisier"
          numeric
          searchable
          sortable
        >
          {{ props.row.fileName }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :width="800"
          :label="$t('Status')"
          field="status"
          searchable
          sortable
        >
          <strong>{{ props.row.status.code }}</strong>
        </b-table-column>

        <b-table-column
          v-slot="props"
          :width="200"
          :label="$t('Date')"
          centered
          field="timeStamp"
          searchable
          sortable
        >
          {{ formatDate(props.row.timeStamp) }}
        </b-table-column>

        <b-table-column
          v-slot="props"
          :label="$t('Options')"
          :width="500"
          centered
          @onContextMenu="onContextMenu"
        >
          <div class="buttons are-small is-centered">
            <b-button
              size="is-small is-primary is-light"
              @click.prevent="viewTask(props.row)"
              >{{ $t("View") }}</b-button
            >
            <b-button
              v-if="props.row.status.statusID === 3"
              type="is-success"
              label="Download"
              @click.prevent="downloadTaskDetails(props.row)"
            />
            <!--   <select v-model="newStatus">
              <option :value="status.new">Status new</option>
              <option :value="status.resume">Status resume</option>
              <option :value="status.canceled">Status canceled</option>
            </select>
            <b-button
              size="is-small is-danger"
              @click.prevent="changeStatus(props.row.taskID, newStatus)"
              >{{ $t("Change status") }}</b-button
            > -->
            <div
              class="buttons are-small is-centered"
              @contextmenu.prevent="onContextMenu($event, props.row)"
            >
              <p>Right Click to Change Status</p>
            </div>
            <div></div>
          </div>
        </b-table-column>
      </b-table>

      <div v-else-if="!isLoading" class="has-text-centered has-text-danger">
        {{ $t("There are no tasks yet.") }}
      </div>
    </section>
    <b-modal v-model="isViewMode" :width="800" scroll="keep">
      <div class="card" style="max-height: 640px">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t("View") }}</p>
          <button class="delete" type="button" @click="isViewMode = false" />
        </header>
        <section class="modal-card-body" v-if="task && task.taskDetails">
          <b-table :data="task.taskDetails" :columns="columns"></b-table>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Close"
            type="is-danger"
            @click="isViewMode = false"
          />
          <b-button
            type="is-success"
            label="Download"
            @click.prevent="downloadTaskDetails(task)"
          />
        </footer>
      </div>
    </b-modal>
    <b-loading v-model="isLoading"></b-loading>
    <vue-context ref="contextMenu">
      <li>
        <a
          @click.prevent="
            changeStatus($refs.contextMenu.data.model.taskID, status.new)
          "
        >
          New
        </a>
      </li>
      <li>
        <a
          @click.prevent="
            changeStatus($refs.contextMenu.data.model.taskID, status.resume)
          "
        >
          Resume
        </a>
      </li>
      <li>
        <a
          @click.prevent="
            changeStatus($refs.contextMenu.data.model.taskID, status.canceled)
          "
          >Cancel</a
        >
      </li>
    </vue-context>
  </div>
</template>

<script>
import { formatDate, replaceFlags } from "@/utils";
import { mapGetters } from "vuex";
import VueContext from "vue-context";
import "vue-context/dist/css/vue-context.css";
export default {
  name: "ViewTasks",
  computed: {
    ...mapGetters({
      selectedCityModule: "GET_CITY_MODULE"
    }),
    filename() {
      if (this.task) {
        return this.task.taskID + ".xls";
      } else {
        return 0;
      }
    }
  },
  components: { VueContext },
  data() {
    return {
      status: {
        new: 1,
        canceled: 5,
        resume: 6
      },
      tasks: null,
      taskDetails: [],
      normalizedTasks: [],
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 20,
      task: null,
      isViewMode: false,
      columns: [
        {
          field: "taskDetailID",
          label: "ID",
          width: "40",
          numeric: true
        },
        {
          field: "statusCode",
          label: "Status"
        },
        {
          field: "judet",
          label: "Judet"
        },
        {
          field: "uat",
          label: "UAT"
        },
        {
          field: "nr_cad",
          label: "Nr Cadastral"
        }
      ]
    };
  },
  mounted() {
    document.title = this.$t("Tasks") + " - GIS ANCPI";
    this.$scrollToTop();
    this.getTasks();
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("Tasks") + " - GIS ANCPI";
    }
  },
  methods: {
    onContextMenu(event, model) {
      return this.$refs.contextMenu.open(event, { model });
    },
    changeStatus(taskyId, newStatus) {
      this.isLoading = true;
      this.$store
        .dispatch("changeStatus", {
          taskId: taskyId,
          newStatus: newStatus
        })
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: "Task updated successfully",
            type: "is-danger",
            position: "is-bottom"
          });
          this.getTasks();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(
              error.response ? error.response.data.message : error
            ),
            type: "is-danger",
            position: "is-bottom"
          });
        });
    },
    getTasks() {
      this.isLoading = true;
      this.$store
        .dispatch("getTasksForUser")
        .then((response) => {
          this.isLoading = false;
          this.tasks = response.data;
          this.normalizeTasks();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 403) {
            this.$store
              .dispatch("signOut")
              .then((response) => {
                console.log(response);
                this.$router.push("/sign-in");
              })
              .catch((error) => {
                console.log(error);
                this.$router.push("/sign-in");
              });
          } else {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.$t(
                error.response ? error.response.data.message : error
              ),
              type: "is-danger",
              position: "is-bottom"
            });
          }
        });
    },
    confirmDeleteTask(task) {
      this.$buefy.dialog.confirm({
        title: this.$t("Deleting task"),
        message: this.$t(
          "Are you sure you want to <b>delete</b> this task? This action cannot be undone."
        ),
        confirmText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteTask(task)
      });
    },
    deleteTask(task) {
      this.isLoading = true;
      this.$store
        .dispatch("deleteTask", task)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: replaceFlags(this.$t("Task %s has been deleted!"), [
              task.taskID
            ]),
            type: "is-success",
            position: "is-bottom"
          });
          this.getTasks();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(
              error.response ? error.response.data.message : error
            ),
            type: "is-danger",
            position: "is-bottom"
          });
        });
    },
    confirmProcessTasks() {
      this.$buefy.dialog.confirm({
        title: this.$t("Processing tasks"),
        message: this.$t("Are you sure you want to <b>process</b> all tasks?"),
        confirmText: this.$t("Process"),
        cancelText: this.$t("Cancel"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.processTasks()
      });
    },
    processTasks() {
      this.isLoading = true;
      this.$store
        .dispatch("processTasks")
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.getTasks();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            type: "is-danger",
            position: "is-bottom"
          });
        });
    },
    formatDate(date) {
      return formatDate(
        date,
        this.$store.state.language,
        "DD-MM-YYYY HH:mm:ss"
      );
    },
    normalizeTasks() {
      this.normalizedTasks = [];
      for (let x = 0; x < this.tasks.length; x++) {
        const task = this.tasks[x];
        this.normalizedTasks.push({
          ...task
        });
      }
    },
    viewTask(taskRow) {
      this.task = taskRow;
      this.isLoading = true;
      this.$store
        .dispatch("getTaskDetails", taskRow.taskID)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          if (this.task && this.task.taskType === 3) {
            this.columns = [
              {
                field: "webID",
                label: "ID",
              },
              {
                field: "administrativeNumber",
                label: "Administrative number"
              },
              {
                field: "location.coordinates",
                label: "Locatia"
              }
            ];
          } else
            this.columns = [
              {
                field: "taskDetailID",
                label: "ID",
                width: "40",
                numeric: true
              },
              {
                field: "statusCode",
                label: "Status"
              },
              {
                field: "judet",
                label: "Judet"
              },
              {
                field: "uat",
                label: "UAT"
              },
              {
                field: "nr_cad",
                label: "Nr Cadastral"
              }
            ];

          this.task.taskDetails = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(
              error.response ? error.response.data.message : error
            ),
            type: "is-danger",
            position: "is-bottom"
          });
        });

      this.isViewMode = true;
    },
    downloadTaskDetails(task) {
      this.$store
        .dispatch("downloadTaskDetails", task.taskID)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", task.taskID + ".xls");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$buefy.toast.open({
            duration: 3000,
            message: this.$t(
              error.response ? error.response.data.message : error
            ),
            type: "is-danger",
            position: "is-bottom"
          });
        });
    }
  }
};
</script>

<style scoped></style>
